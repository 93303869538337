// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-regulatory-disclosures-js": () => import("./../../../src/pages/regulatory-disclosures.js" /* webpackChunkName: "component---src-pages-regulatory-disclosures-js" */),
  "component---src-templates-daily-update-list-template-js": () => import("./../../../src/templates/daily-update-list-template.js" /* webpackChunkName: "component---src-templates-daily-update-list-template-js" */),
  "component---src-templates-fund-template-js": () => import("./../../../src/templates/fund-template.js" /* webpackChunkName: "component---src-templates-fund-template-js" */),
  "component---src-templates-information-template-js": () => import("./../../../src/templates/information-template.js" /* webpackChunkName: "component---src-templates-information-template-js" */),
  "component---src-templates-news-list-template-js": () => import("./../../../src/templates/news-list-template.js" /* webpackChunkName: "component---src-templates-news-list-template-js" */),
  "component---src-templates-news-template-js": () => import("./../../../src/templates/news-template.js" /* webpackChunkName: "component---src-templates-news-template-js" */)
}

